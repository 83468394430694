import { EventEmitter } from "@angular/core";

export abstract class SpecificDataComponent {

  service: string;
  type: FormType;
  autofill: boolean;
  askForNationality = false;
  
  abstract isValid(): boolean;
  
  valueChanged: EventEmitter<any> = new EventEmitter<any>();

  protected emitValueChange(value: any): void {
    this.valueChanged.emit(value);
  }
  
  abstract getValue(): any;
}

export enum FormType {
  ccaa = 'ccaa',
  cpm = 'cpm',
  date = 'date',
  person = 'person',
  identity = 'identity',
  identity_support = 'identity_support'
}