import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  private nifNieRegex = /^(?:\d{8}[A-Za-z]|\d{7}[A-Za-z]{1}|[X-Y]{1}\d{7}[A-Za-z]{1})$/;
  private numeroSoporteRegex = /^(?:[C|E]{1}\d{8}|[A-Za-z]{3}\d{6})$/;

  private allowedLettersForNIF = [ 'T', 'R', 'W', 'A', 'G', 'M', 'Y', 'F', 'P', 'D', 'X', 'B',
    'N', 'J', 'Z', 'S', 'Q', 'V', 'H', 'L', 'C', 'K', 'E', 'T' ];

  /* constructor() { } */

  public documentValidator(type: string, value: string){
    value = value.toUpperCase();
    if(type == "DNI"){
      if(this.nifNieRegex.test(value)) return this.letterCheck(value);
      else return false;
    }
    else if(type == "NIE"){
      const initialChar = value.charAt(0);
      if(initialChar == 'X'){
        value = "0" + value.slice(1);
        return this.letterCheck(value);
      }
      else if(initialChar == 'Y'){
        value = "1" + value.slice(1);
        return this.letterCheck(value);
      }
      else if(initialChar == 'Z'){
        value = "2" + value.slice(1);
        return this.letterCheck(value);
      }
      else return false;
    }
    else return false;
  }

  private letterCheck(value: string): boolean{
    const verificationLetter =  value.substring(value.length - 1).charAt(0);
    const identityNumbersArr = value.match(/\d+/g);
    const identityNumbers = parseInt(identityNumbersArr != null ? identityNumbersArr[0] : '')
    const pos = identityNumbers % 23;
    return this.allowedLettersForNIF[pos] == verificationLetter;
  }

  public numSoporteValidator(value: string){
    return this.numeroSoporteRegex.test(value);
  }
}
