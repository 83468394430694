import { AfterViewInit, Component, QueryList, ViewChildren } from '@angular/core';
import { ManagementService } from '../_service/management.service';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule,ReactiveFormsModule} from '@angular/forms';
import { JwtUtilsService } from '../_service/jwt-utils.service';
import { Router } from '@angular/router';
import { Select2Data, Select2Module, Select2UpdateEvent, Select2UpdateValue } from 'ng-select2-component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.css'],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, MatTableModule, MatSortModule, MatPaginatorModule, CommonModule, FormsModule, ReactiveFormsModule, Select2Module, TranslateModule]
})
export class ManagementComponent implements AfterViewInit{
  levelsForm: FormGroup;
  displayedColumns: string[] = ['id', 'url', 'active', 'actions'];
  columnsProperties: string[] = ['propertyName', 'value', 'actions'];

  loggedUser:any = undefined;
  dataSource: MatTableDataSource<any>;
  dataSourceProperties: MatTableDataSource<any>;
  dataSourceServices: Map<string, MatTableDataSource<any>>;
  dataSourceSelectData: Map<string, Select2Data>;

  myDate: Date = new Date();
  limits = [0,5];
  propertiesLimits = [0,5]
  baseData: any[] = [];
  propertyData: any[] = [];
  @ViewChildren(MatPaginator) paginator: QueryList<MatPaginator>;
  @ViewChildren(MatSort) sort: QueryList<MatSort>;
  scheduler: 0;
  propertyValue: "";
  classLog = "es.oots";
  levels = ["INFO", "DEBUG", "ERROR"];
  types = [{name: "all", selected: true}]
  popUp = {show: false, title: "", message: ""}
  previousScheduler = 0;
  section = "whitelist"
  servicesStates: Map<string, any>
  servicesDataMap: Map<string, any> = new Map<string, any>([["Procedures", ["id", "code", "description", "actions"]], ["Requirements", ["id", "requirementId", "description", "actions"]], 
    ["ProcedureRequirements", ["id", "procedureId", "requirementId", "actions"]], ["PidServiceCatalog", ["id", "serviceName", "pidServiceId", "forms", "endpointUrl", "actions"]], 
    ["RequirementPidCatalog", ["id", "requirementId", "pidServiceId", "autofill", "actions"]]]);
  serviceTypesLeft: string[] = ["Procedures","Requirements","PidServiceCatalog"];
  serviceTypesRight: string[] = ["ProcedureRequirements", "RequirementPidCatalog"];

  visibleInputs = false;
  visibleInputsProperties = false;
  urlInput:string;
  isActive = true;
  menuShow = false;
  loadedData = false;
  editedEntry: any;
  editedValues: any;

  data: Select2Data;


  constructor(private managementService: ManagementService, private router: Router, private jwtUtilsService: JwtUtilsService, private fb: FormBuilder){}
  ngAfterViewInit() {
    this.managementService.getLoggedUser().subscribe({
      next: result => {
        //Delete or comment for deployment and pushing to repo, uncomment for development purposes only
        /* if(result){
          this.loggedUser = result;
        }
        else{
          this.loggedUser = {
            username: "A user",
            role: "ROLE_ADMIN"
          };
        } */
        
        this.loggedUser = result;
        this.getTypes();
        this.initWhitelist();
      }
    })
  }

  initWhitelist(){
    this.section='whitelist'; 
    this.menuShow=false; 
    this.loadedData=false;
    this.search();
  }

  initProperties(){
    this.section='properties'; 
    this.menuShow=false; 
    this.loadedData=false;
    this.previousScheduler = 0;
    this.searchProperties();
    this.getScheduler();
    this.logLevelMethods();
  }

  search() {
    this.loadedData = true;
    this.managementService.getWhitelist(this.limits).subscribe(
      {
         next: result => {
          this.baseData = result;
          this.dataSource = new MatTableDataSource(result);
          this.dataSource.paginator = this.paginator.toArray()[0];
          this.dataSource.sort = this.sort.toArray()[0];
         }
      }
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  nextPage(event: PageEvent) {
    this.limits[0] = event.pageIndex * event.pageSize;
    this.limits[1] = event.pageSize;
    this.search();
  }

  nextPageProperties(event: PageEvent) {
    this.propertiesLimits[0] = event.pageIndex * event.pageSize;
    this.propertiesLimits[1] = event.pageSize;
    this.searchProperties();
  }

  delete(id:number){
    this.managementService.deleteWhitelistEntry(id).subscribe({next: () => {
      this.search()
    }})
  }

  saveEdit(id: number){
    const whitelistEntry =
    {
      "id": id,
      "url": this.urlInput,
      "active": this.isActive 
    }
    this.managementService.saveWhitelistEntry(whitelistEntry).subscribe({next: () => {
      this.visibleInputs = false;
      this.search();
    }})
  }

  save(){
    const whitelistEntry =
    {
      "url": this.urlInput,
      "active": this.isActive 
    }
    this.managementService.saveWhitelistEntry(whitelistEntry).subscribe({next: () => {
      this.visibleInputs = false;
      this.limits[0] = 0;
      this.search();
    }})
  }

  setSaving(){
    const math = Math;
    this.visibleInputs = true;
    this.managementService.getWhitelistLength().subscribe(
      {
         next: result => {
          let limit = math.trunc(result/this.limits[1]);
          limit ++;
          this.limits[0] = limit * this.limits[1];
          this.managementService.getWhitelist(this.limits).subscribe(
            {
               next: result => {
                this.baseData = result;
                this.dataSource = new MatTableDataSource(result);
                const extendedData = this.baseData;
                extendedData.push({id: "", url: "", active:false, actions: "new"});
                this.dataSource = new MatTableDataSource(extendedData);
               }
            }
          )
         }
      }
    )
  }

  applyFilterProperties(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceProperties.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceProperties.paginator) {
      this.dataSourceProperties.paginator.firstPage();
    }
  }

  cancelSave(){
    this.dataSource = new MatTableDataSource(this.baseData);
    this.visibleInputs = false;
    this.limits[0] = 0;
    this.search();
  }
  
  changeScheduler(){
    this.managementService.changeScheduler(this.scheduler).subscribe({next: () => {
      this.getScheduler();
      this.visibleInputs = false;
      this.limits[0] = 0;
    }})
  }
  searchProperties() {
    const selType = this.types.filter(type => type.selected == true).at(0)?.name
    this.loadedData=true;
    this.managementService.getProperties(selType).subscribe(
      {
         next: result => {
          this.propertyData = result;
          this.dataSourceProperties = new MatTableDataSource(result);
          this.dataSourceProperties.paginator = this.paginator.toArray()[0];
          this.dataSourceProperties.sort = this.sort.toArray()[0];
         }
      }
    )
  }
  saveEditedProperty(index: number){

    const property = this.propertyData[index]
    property.value = this.propertyValue
    this.managementService.savePropertiesEntry(property).subscribe({next: () => {
      this.visibleInputsProperties = false;
      this.propertyValue = "";
      this.searchProperties();
    }})
  }
  logout(){
    this.managementService.logoutUser().subscribe({next: () => {
        this.router.navigate(['/login-maintenance']);
      },
      error: () =>{
        this.router.navigate(['/login-maintenance'])
      }
    }
    
    )
  }
  logLevelMethods(loglevel?:string){
    this.managementService.logLevelServices(this.classLog, loglevel).subscribe({next: response => {
      if(loglevel){
        this.popUp = {show: true, title: "Log level changed", message: response + ""}
        setTimeout(() => this.popUp.show = false, 4000);
      }
      else{
        this.levelsForm = this.fb.group({
          levelControl: [response]
        })
      }
    }})
  }

  getTypes(){
    this.managementService.getPropertyTypes().subscribe(
      {
         next: result => {
          result.forEach((type: string) => {
            this.types.push({name: type, selected: false})
          })
         }
      }
    )
  }
  getScheduler(){
    this.managementService.getScheduler().subscribe({ next: result => {
      this.scheduler = result;
      if(this.previousScheduler != 0){
        this.popUp = {show: true, title: "Scheduler changed", message: "Scheduler time changed to " + this.scheduler + "ms, the change will be executed in " + this.previousScheduler + "ms at most."}
        setTimeout(() => this.popUp.show = false, 4000);
      }
      this.previousScheduler = result;
    }

    })
  }

  changeType(index: number){
    this.types.forEach(type => {type.selected = false})
    this.types[index].selected = true;
    this.searchProperties();
    
  }

  capitalize(str: string){
    return String(str).charAt(0).toUpperCase() + String(str).slice(1)
  }

  initServices(){
    this.section='services'; 
    this.menuShow=false; 
    this.loadedData=false;
    this.loadServicesLists();
    
  }

  loadServicesLists(){
    let i = 0;
    this.servicesStates = new Map<string, any>;
    this.dataSourceServices = new Map<string, MatTableDataSource<any>>;
    console.log(this.servicesDataMap);
    this.dataSourceSelectData = new Map<string, Select2Data>;
    this.managementService.getAllServiceLists().subscribe((response) => {
      const mappedResponse = new Map<string, any>(Object.entries(response));
      mappedResponse.forEach((value:any, key:any) => {
        const dataSrc = new MatTableDataSource(value);
        switch(key){
          case "Procedures":
            i=0
            break;
          case "Requirements":
            i=1
            
            break;
          case "PidServiceCatalog":
            i=2
            break;
          case "ProcedureRequirements":
            i=3
            break;
          case "RequirementPidCatalog":
            i=4
            break;
        }
        const serviceState = {
          index: i,
          state: 'closed'
        }

        this.getSelect2Data(key, value);

        this.servicesStates.set(key, serviceState);
        dataSrc.paginator = this.paginator.toArray()[i];
        dataSrc.sort = this.sort.toArray()[i];
        this.dataSourceServices.set(key, dataSrc);
        
      })
      this.loadedData = true;
      console.log(this.dataSourceServices);
    })
  }

  saveServiceProperty(type:string, property:any){
    switch(type){
    case "Procedures":
      this.managementService.saveProcedure(property).subscribe({next:() => {
        this.visibleInputsProperties = false;
        this.getListByType("Procedures");
        this.editedEntry = {};
      }});
      break;
    case "Requirements":
      this.managementService.saveRequirement(property).subscribe({next:() => {
        this.visibleInputsProperties = false;
        this.getListByType("Requirements");
        this.editedEntry = {};
      }});
      break;
    case "PidServiceCatalog":
      this.managementService.savePidServiceCatalog(property).subscribe({next:() => {
        this.visibleInputsProperties = false;
        this.getListByType("PidServiceCatalog");
        this.editedEntry = {};
      }});
      break;
    case "ProcedureRequirements":
      this.managementService.saveProcedureRequirement(property).subscribe({next:() => {
        this.visibleInputsProperties = false;
        this.getListByType("ProcedureRequirements");
        this.editedEntry = {};
        this.editedValues = {};
      }});
      break;
    case "RequirementPidCatalog":
      this.managementService.saveRequirementPidCatalog(property).subscribe({next:() => {
        this.visibleInputsProperties = false;
        this.getListByType("RequirementPidCatalog");
        this.editedEntry = {};
        this.editedValues = {};
      }});
      break;
    }
  }

  getListByType(type:string){
    this.managementService.getServiceListByType(type).subscribe((list) => {
      const index = this.servicesStates.get(type).index;
      if(index != undefined){
        const serviceState = {
          index: index,
          state: 'open'
        }
        this.servicesStates.set(type, serviceState);
        const dataSrc = new MatTableDataSource(list);
        dataSrc.paginator = this.paginator.toArray()[index];
        dataSrc.sort = this.sort.toArray()[index];
        this.dataSourceServices.set(type, new MatTableDataSource(list));
      }
      
    }

  )
  }

  setServiceEditedEntry(serviceEntry:any, inputType?:any, type?:string){
    this.editedEntry = structuredClone(serviceEntry);
    
    delete this.editedEntry.actions;
    this.editedValues = structuredClone(this.editedEntry);
    if(inputType != undefined && inputType=='select' && type != undefined){
      let value;
      const atributeNames:string[] = this.servicesDataMap.get(type) ?? undefined;
      atributeNames.forEach(atributeName => {
        if(typeof serviceEntry[atributeName] === 'object'){
          this.editedValues[atributeName] = serviceEntry[atributeName].id;
        }

      })
      typeof value === 'object'
    }
    this.visibleInputsProperties = true;
    console.log(this.editedEntry)
  }

  setServiceSavingEntry(type:string){
    const mapObj = new Map<string, any>;
    const mapEdited = new Map<string, any>;
    const atributeNames:string[] = this.servicesDataMap.get(type) ?? undefined;
    if(atributeNames != undefined){
      atributeNames.forEach(atributeName => {
        mapObj.set(atributeName, undefined);
        if(atributeName !== 'actions'){
          mapEdited.set(atributeName, undefined);
        }
      })
      this.editedEntry = Object.fromEntries(mapEdited.entries());
      this.editedValues = Object.fromEntries(mapEdited.entries());
      const serviceEntry:any = Object.fromEntries(mapObj.entries());
      serviceEntry.actions = 'new'
      const extendedData = [];
      extendedData.push(serviceEntry);
      this.dataSourceServices.set(type, new MatTableDataSource(extendedData));
      
  
      this.visibleInputsProperties = true;
      console.log(this.dataSourceServices.get(type))
    }
    else{
      this.popUp = {show: true, title: "Error", message: "Unexpected error, cannot add entry"}
        setTimeout(() => this.popUp.show = false, 4000);
    }

    
  }

  getSelect2Data(key:string, objList: any[]){
    if(objList[0].description != null){
      const dataList: Select2Data = objList.map((obj:any) => ({
        value: obj,
        label: obj.id + ': ' + obj.description,
      }));
      this.dataSourceSelectData.set(key, dataList);
    }
    else if(objList[0].serviceName != null){
      const dataList: Select2Data = objList.map((obj:any) => ({
        value: obj,
        label: obj.id + ': ' + obj.serviceName,
      }));
      this.dataSourceSelectData.set(key, dataList);
    }
    
  }

  setSelectedOption(event:Event, type:string, attr: string) {
    const serviceData = this.dataSourceServices.get(type)?.data ?? undefined;
    if(serviceData){
      const idEntry = Number((event.target as HTMLInputElement).value);
      this.editedEntry[attr] = serviceData.find(option => option.id === idEntry); 
    }
  }

  setSelectedOptionData(event:Select2UpdateEvent<Select2UpdateValue>, attr: string) {
    const type = this.getServiceTypeByAttr(attr);

    const serviceData = this.dataSourceServices.get(type)?.data ?? undefined;
    if(serviceData){
      const idEntry: any = event.value;
      this.editedEntry[attr] = serviceData.find(option => option.id === idEntry.id); 
    }
  }

  cancelSaveService(type:string){
    this.getListByType(type);
    this.visibleInputsProperties = false;
  }
  applyFilterService(event:Event, type:string){
    const dSource = this.dataSourceServices.get(type);
    if(dSource !== undefined && dSource.filter!==undefined){
      const filterValue = (event.target as HTMLInputElement).value;
      dSource.filter = filterValue.trim().toLowerCase();
      if (dSource.paginator) dSource.paginator.firstPage();
      this.dataSourceServices.set(type, dSource);
    }
    console.log(this.dataSourceServices)
  }

  closeTable(type: string){
    const index = this.servicesStates.get(type).index;
      if(index != undefined){
        const serviceState = {
          index: index,
          state: 'closed'
        }
        this.servicesStates.set(type, serviceState);
    }
  }

  deleteServiceEntry(type:string, id:number){
    this.managementService.deleteByIdAndType(type, id).subscribe({next: () =>{
      this.getListByType(type);
    }})
  }

  getServiceTypeByAttr(attr: string): string{
    let type = "";
    switch(attr){
      case ("requirementId"):
        type="Requirements"
        break;
      case ("procedureId"):
        type = "Procedures"
        break;
      case ("pidServiceId"):
        type = "PidServiceCatalog"
    }
    return type;
  }

  getServiceTypes(): string[]{
    return Array.from(this.servicesDataMap.keys());
  }

  translateAttrNames(name: string): string {
    name = name.charAt(0).toLowerCase() + name.slice(1);
    return name.replace(/([A-Z])/g, '_$1').toUpperCase().split('.')[0];
  }

  checkType(value: any, type: string): boolean{
    return typeof value === type;
  }
}
