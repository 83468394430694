<!DOCTYPE html>
<html>

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <title>OOTS Intermediary App</title>
</head>

<body style="height: 100%;">

  <!-- Barra de navegación -->

  <header class="header" style="background-color: white;">
    <div class="container">
      <div class="d-flex align-items-center justify-content-between bg-light py-3 px-4"
        style="background-color: white !important;">

        <!-- Logos e información a la izquierda -->
        <div class="d-flex align-items-center">
          <img height="42" alt="Logo" src="assets/logo-gobierno.svg" />
          <img height="48" alt="Logo" src="assets/ye.png" />
          <div class="vl"></div>
          <div class="ms-3 lh-1">
            <span class="d-block fs-2 fw-bold mb-0">OOTS</span>
            <div class="d-flex align-items-center" style="padding: 0px;">
              <span class="fs-5 text-muted">
                {{'HOME.GATEWAY' | translate}}
                <app-version [app]="true"></app-version>
              </span>
            </div>
          </div>

        </div>

        <!-- Selector de idioma y versión de la aplicación -->
        <div class="d-flex flex-column align-items-end">
          <select style="border-width: 0px; height: 36px;" class="form-select w-auto mb-2"
            [(ngModel)]="selectedLanguage" #mySelect (change)="translateLanguageTo(mySelect.value)">
            <option value="en">English</option>
            <option value="es">Español</option>
          </select>
          <app-version [tdd]="!isPublic" class="tdd"></app-version>
        </div>

      </div>
    </div>
  </header>

  <main>

    <!-- Breadcrum with timer and buttons -->
    <app-header></app-header>

    <!-- Contenido principal -->
    <section class="content">
      <div class="root-container" *ngIf="innerHtml === ''">
        <router-outlet></router-outlet>
      </div>
      <div *ngIf="innerHtml !== ''" class="root-container">
        <div class="d-flex justify-content-end">
          <button (click)="innerHtml=''">X</button>
        </div>
        <div [innerHtml]="innerHtml">
        </div>
      </div>
    </section>
  </main>

  <!-- Pie de página -->
  <footer class="footer" style="background-color: rgba(247, 247, 248, 1);">
    <div class="container" style="max-width: 100%; padding: 0px; text-align: center;">
      <div style="background-color:  rgba(17, 77, 166, 1); padding: 1rem 1rem; ">
        <span style="padding: 24px 16px; margin-right: 70px;">
          <a tabindex="0" (click)="openComponent('/aviso_legal')" href="javascript:void(0);"
            style="color: white"><u>{{'FOOTER.LEGAL_WARNING' | translate}}</u></a>
        </span>
        <span style="padding: 24px 16px; margin-right: 70px;">
          <a tabindex="0" (click)="openComponent('/politica_privacidad')" href="javascript:void(0);"
            style="color: white"><u>{{'FOOTER.PRIVACY_POLICY' | translate}}</u></a>
        </span>
        <span style="padding: 24px 16px; margin-right: 70px;">
          <a tabindex="0" (click)="openComponent('/politica_cookies')" href="javascript:void(0);"
            style="color: white"><u>{{'FOOTER.COOKIES_POLICY' | translate}}</u></a>
        </span>
      </div>

      <img src="assets/europa.svg" alt="" style="padding: 24px 16px; margin-right: 70px;">
      <img src="assets/plan_recuperacion.svg" alt="" style="padding: 24px 16px; margin-right: 70px;">
      <img src="assets/espana_digital.svg" alt="" style="padding: 24px 16px; margin-right: 70px;">
    </div>

  </footer>

  <button id="open-server-session-timeout-dialog" (click)="openServerSessionTimeoutDialog()"
    style="display: none;">server-session-timeout</button>
  <button id="open-client-session-timeout-dialog" (click)="openClientSessionTimeoutDialog()"
    style="display: none;">client-session-timeout</button>

  <!-- Incluir los enlaces a los archivos JavaScript de Bootstrap -->
  <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.5.4/dist/umd/popper.min.js"></script>
  <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/js/bootstrap.min.js"></script>
  <!-- Incluir los enlaces a los archivos CSS de Bootstrap -->
  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css">
  <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css">
</body>

</html>