import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormType, SpecificDataComponent } from '../specific-data.component';
import { Select2Data } from 'ng-select2-component';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { JwtUtilsService } from 'src/app/_service/jwt-utils.service';
import { EidasUser } from 'src/app/_model/eidasUser';
import { UtilsService } from 'src/app/_service/utils.service';

@Component({
  selector: 'app-cpm',
  templateUrl: './cpm.component.html',
  styleUrls: ['./cpm.component.css']
})
export class CpmComponent extends SpecificDataComponent implements OnInit {

  cpmData : CpmData;
  jsonData: any;
  comunidades: Comunidad[] = [];
  provincias: Provincia[] = [];
  municipios: Municipio[] = [];
  comunidadesData: Select2Data = [];
  provinciasData: Select2Data = [];
  municipiosData: Select2Data = [];
  spanishCitizen: boolean | null = null;

  selectedComunidad: Comunidad | null = null;
  selectedProvincia: Provincia | null = null;
  selectedMunicipio: Municipio | null = null;
  birthDate: string | null = null;
  identifier: string | null = null;
  identityType = "DNI";
  supportNumber: string | null;

  eidasUser: EidasUser;

  cpmForm: UntypedFormGroup = new UntypedFormGroup({
    comunidad: new UntypedFormControl,
    provincia: new UntypedFormControl,
    municipio: new UntypedFormControl
  })
  personForm: FormGroup = new FormGroup({
    nombre: new FormControl(null, Validators.required),
    apellido1: new FormControl(null, Validators.required),
    apellido2: new FormControl
  })
  constructor(
    private http: HttpClient,
    private jwtUtils: JwtUtilsService,
    private utils: UtilsService,
  ) {
    super();
    
  }

  ngOnInit(): void {
    if(this.autofill){
      this.eidasUser = this.jwtUtils.getCurrentUser();
      if(this.type == FormType.date){
        this.birthDate = this.eidasUser.dateOfBirth.toString().split('T')[0];
      }
      if(this.type == FormType.identity){
        this.identifier = this.eidasUser.personIdentifier;
      }
      else if(this.type == FormType.person){
        this.personForm.controls['nombre'].setValue(this.eidasUser.firstName);
      }
    }

    this.cpmData = new CpmData();
    // Cargar los datos del JSON
    this.http.get<any>('assets/cpm.json').subscribe(response => {
        this.jsonData = response;
        if(response.comunidades) this.comunidadesData = response.comunidades.map((comunidad:Comunidad) => ({
          value: comunidad,
          label: comunidad.nombre,
        }));
        //this.comunidades = response.comunidades;
      }
    );
  }

  onComunidadChange(): void {
    const comunidad = this.cpmForm.controls['comunidad'].getRawValue() as Comunidad;
    if (comunidad) {
      this.selectedProvincia = null;
      this.selectedMunicipio = null;
      this.selectedComunidad = comunidad;
      this.listProvincias(comunidad);

      /* if (this.provinciasData.length === 1 && comunidad.provincias!== undefined) {       // Autoseleccionar la provincia si solo hay una
        this.selectedProvincia = comunidad.provincias[0];
        
        //this.cpmForm.controls['provincia'].reset(this.selectedProvincia.nombre)
        this.cpmForm.controls['provincia'].setValue(this.selectedProvincia.nombre)
        this.listMunicipios(this.selectedProvincia);
        this.cpmData.codeProvincia =  this.selectedProvincia.codigo;
      } else {
        this.municipiosData = [];
        this.selectedProvincia = null;
        this.selectedMunicipio = null;
      } */
      this.municipiosData = [];
      this.selectedProvincia = null;
      this.selectedMunicipio = null;

      this.cpmData.codeComunidad = this.selectedComunidad.codigo;
      this.emitValueChange(this.cpmData);
    }
  }

  onProvinciaChange(): void {
    const provincia = this.cpmForm.controls['provincia'].getRawValue() as Provincia;
    this.cpmData.codeProvincia = null;
    this.cpmData.codeMunicipio = null;
    if (provincia) {
      this.selectedProvincia = provincia;
      this.listMunicipios(this.selectedProvincia)
      //this.municipios = provincia.municipios ?? [];
      this.selectedMunicipio = null;
      this.cpmData.codeProvincia = this.selectedProvincia.codigo;
      this.emitValueChange(this.cpmData);
    }
  }

  onMunicipioChange(): void {
    const municipio = this.cpmForm.controls['municipio'].getRawValue() as Municipio;
    this.selectedMunicipio = municipio;
    this.cpmData.codeMunicipio = this.selectedMunicipio.codigo;
    this.emitValueChange(this.cpmData);
  }

  listProvincias(comunidad: Comunidad){
    if(comunidad.provincias) this.provinciasData = comunidad.provincias.map((provincia:Provincia) => ({
      value: provincia,
      label: provincia.nombre,
    }));
  }

  listMunicipios(provincia: Provincia){
    if(provincia.municipios)  this.municipiosData = provincia.municipios.map((municipio:Municipio) => ({
      value: municipio,
      label: municipio.nombre,
    }));
  }


  override isValid(): boolean {
    if(this.type === FormType.ccaa) return this.selectedComunidad !== null;
    if(this.type === FormType.date) return this.birthDate !== null;
    if(this.type === FormType.identity) {
      if(this.identifier == null) return false;
      if(!this.utils.documentValidator(this.identityType, this.identifier)) return false;
      if((this.supportNumber == null || this.supportNumber == "") && this.birthDate == null) return false
      return true;
    }
    if(this.type === FormType.cpm) {
      return this.selectedComunidad !== null && this.selectedProvincia !== null && this.selectedMunicipio !== null;
    }
    if(this.type === FormType.person){
      if(this.autofill){
        if(this.eidasUser){
          if(this.personForm.controls["nombre"].value === this.eidasUser.firstName && this.personForm.controls["apellido1"].value + " " + this.personForm.controls["apellido2"].value === this.eidasUser.familyName){
            return this.personForm.valid;
          }
          else{
            return false;
          }
        }
      }
      else{
        return this.personForm.valid;
      }
      
    }
    return false;
  }

  override getValue(): any {
    switch(this.type){
      case (FormType.ccaa):
        return this.cpmData;
      break;
      case (FormType.cpm):
        return this.cpmData;
      break;
      case (FormType.date):
        return this.birthDate;
      case (FormType.person):
        return this.personForm.value;
      break;
      default:
        return null;
    }
    
  }

  spanishCitizenChange() {
    this.cpmData.spanishCitizen = this.spanishCitizen;
    this.selectedMunicipio = null;
    this.selectedProvincia = null;
    this.selectedComunidad = null;
  }

  identifierTypeChange(){
    if(this.identityType == "DNI"){
      this.identifier = this.eidasUser.personIdentifier;
      this.birthDate = this.eidasUser.dateOfBirth.toString().split('T')[0]
    }
    else if(this.identityType == "NIE"){
      this.identifier = null;
      this.birthDate = null;
    }
    this.emitValueChange(this.identifier);
  }
  
}

interface Municipio {
  codigo: string;
  nombre: string;
}

interface Provincia {
  codigo: string;
  nombre: string;
  municipios?: Municipio[];
}

interface Comunidad {
  codigo: string;
  nombre: string;
  provincias?: Provincia[];
}

export class CpmData  {
  codeComunidad: string | null = null;
  codeProvincia: string | null = null;
  codeMunicipio: string | null = null;
  spanishCitizen: boolean | null = null;
}