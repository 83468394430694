
<div *ngIf="askForNationality; else cpm" class="form-gr">
    <div class="step radio-container">
        <div>
            <div [ngClass]="spanishCitizen === null ? 'fa-solid fa fa-circle-chevron-right fa-lg' : 'fa-solid fa fa-circle-check fa-lg'"></div>
        </div>
        <label for="nationality">Es usted ciudadano nacido en España?</label>
        <div class="radio-group">
            <input type="radio" id="spanishYes" name="nationality" [(ngModel)]="spanishCitizen" [value]="true" (change)="spanishCitizenChange()">
            <label for="spanishYes">Si</label>
        </div>
        <div class="radio-group">
            <input type="radio" id="spanishNo" name="nationality" [(ngModel)]="spanishCitizen" [value]="false" (change)="spanishCitizenChange()">
            <label for="spanishNo">No</label>
        </div>
    </div>

    <div *ngIf="spanishCitizen !== null">
        <div class="step cpm-step" *ngIf="spanishCitizen !== null">
            <div [ngClass]="isValid() ? 'fa-solid fa-circle-check fa-lg' : 'fa-solid fa-circle-chevron-right fa-lg'"></div>
            <label *ngIf="spanishCitizen" for="ambito">Seleccione su municipio de nacimiento</label>
            <label *ngIf="!spanishCitizen" for="ambito">Seleccione su municipio de residencia</label>
        </div>
        <ng-container *ngTemplateOutlet="cpm"></ng-container>
    </div>
</div>

<ng-template #cpm>
    <ng-container *ngIf="type === 'ccaa' || type === 'cpm'; else other" class="form-gr">
        <form [formGroup]="cpmForm" class="m-0">
            <div class="cpm-container">
                <div class="select-group">
                    <label for="comunidad">{{ 'PREVIEW_SPACE.COMMUNITY' | translate }}</label>
                    <select2 id="comunidad" [data]="comunidadesData" class="select2" (update)="onComunidadChange()" listPosition="above" formControlName="comunidad">
                    </select2>
                </div>
            
                <div class="select-group" [ngClass]="{'hidden': type === 'ccaa'}">
                    <label for="provincia">{{ 'PREVIEW_SPACE.PROVINCE' | translate }}</label>
                    <select2 id="provincia" [data]="provinciasData" class="select2" (update)="onProvinciaChange()" listPosition="above" formControlName="provincia">
                    </select2>
                </div>
            
                <div class="select-group" [ngClass]="{'hidden': type === 'ccaa'}">
                    <label for="municipio">{{ 'PREVIEW_SPACE.MUNICIPALITY' | translate }}</label>
                    <select2 id="municipio" [data]="municipiosData" class="select2" (update)="onMunicipioChange()" listPosition="above" formControlName="municipio">
                    </select2>
                </div>
            </div>
        </form>
    </ng-container>
</ng-template>
<ng-template #other>
    <div class="form-gr">
        <div *ngIf="type === 'person'">
            <form [formGroup]="personForm" class="m-0">
                <div class="cpm-container">
                    <div class="select-group">
                        <label for="nombre">{{ 'PREVIEW_SPACE.NAME' | translate }}</label>
                        <input [disabled]="autofill" id="nombre" formControlName="nombre" type="text" (change)="emitValueChange(birthDate)">
                    </div>
                    <div class="select-group">
                        <label for="apellido1">{{ 'PREVIEW_SPACE.SURNAME1' | translate }}</label>
                        <input id="apellido1" formControlName="apellido1" type="text" (change)="emitValueChange(birthDate)">
                    </div>
                    <div class="select-group">
                        <label for="apellido2">{{ 'PREVIEW_SPACE.SURNAME2' | translate }}</label>
                        <input id="apellido2" formControlName="apellido2" type="text" (change)="emitValueChange(birthDate)">
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="type === 'date'">
            <div class="select-group">
                <label for="birthDate">{{ 'PREVIEW_SPACE.BIRTH_DATE' | translate }}</label>
                <input [disabled]="autofill" id="birthDate" type="date" data-date-format="YYYY-MM-DD" [(ngModel)]="birthDate" (change)="emitValueChange(birthDate)">
            </div>
        </div>
        <div *ngIf="type === 'identity' || type === 'identity_support'">
            <div class="select-group">
                <select name="" id="" [(ngModel)]="identityType" (change)="identifierTypeChange()">
                    <!-- <option value="">{{'PREVIEW_SPACE.SELECT_TYPE_IDENTITY' | translate}}</option> -->
                    <option>DNI</option>
                    <option>NIE</option>
                </select>
            </div>
            <div class="select-group">
                <label for="identifier">{{ 'PREVIEW_SPACE.IDENTIFIER' | translate }}</label>
                <input [disabled]="autofill" id="identifier" type="text"  [(ngModel)]="identifier" (change)="emitValueChange(identifier)">
            </div>
            <div class="select-group" *ngIf="identityType === 'NIE' && type === 'identity_support'">
                <label for="supportNumber">{{ 'PREVIEW_SPACE.SUPPORT_NUMBER' | translate }}</label>
                <input id="supportNumber" type="text" [(ngModel)]="supportNumber" (change)="emitValueChange(supportNumber)">
            </div>
            <div class="select-group">
                <label for="birthDate">{{ 'PREVIEW_SPACE.BIRTH_DATE' | translate }}</label>
                <input [disabled]="autofill" id="birthDate" type="date" data-date-format="YYYY-MM-DD" [(ngModel)]="birthDate" (change)="emitValueChange(birthDate)">
            </div>
        </div>
    </div>
</ng-template>
