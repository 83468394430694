<div *ngIf="step === 2" class="requirement row" style="position: relative;">

    <div class="d-flex align-items-center">
        <p class="no-margin-bottom me-3">
            <label for="requirement-number" class="requirement-number">{{ requirement.number }}</label>
        </p>
        <p class="no-margin-bottom">
            <label for="requirement-title" class="requirement-title">{{ requirement.title }}</label>
        </p>
    </div>

    <div *ngIf="isAnyEvidenceReceived; then isEvidenceReceived else isNotEvidenceReceived"></div>

    <ng-template #isEvidenceReceived>
        <ng-container *ngTemplateOutlet="evidenceReceived; context: { evidence: receivedEvidence }"></ng-container>
    </ng-template>

    <ng-template #isNotEvidenceReceived>
        <div class="col-md-10">

            <!-- <select [(ngModel)]="selectedCountry" id="evidenceCountry" (change)="onCountryChange($event)"
                [disabled]="sendButton !== undefined && sendButton.isLoading">
                <option selected value="null">{{'EV_REQUESTED.SELECT_COUNTRY' | translate}}</option>
                <option *ngFor="let country of countryList" [value]="country.code">{{ country.name }}
                </option>
            </select> -->

            <select2 [data]="countryList" [value]="selectedCountry" [templates]="template" class="select2" 
            (update)="onCountryChange($event)" [disabled]="sendButton !== undefined && sendButton.isLoading"
            [placeholder]="getPlaceHolder()" listPosition="auto">

                <ng-template #template let-data="data" let-label="label">
                  <div class="country-container">
                    <div *ngIf="data?.image">
                      <img [src]="data.image" alt="{{ label }}" />
                    </div>
                    <span>{{ label }}</span>
                  </div>
                </ng-template>
              </select2>

        </div>

        <div class="col-md-2">
            <app-loader-button [disabled]="!selectedCountry || selectedCountry ==='null' || selectedCountry === 'NONE'"
                class="retrieve-button" (buttonClicked)="searchEvidence()" [icon]="'fa fa-search'"></app-loader-button>
        </div>

        <div *ngIf="isSearchDone">
            <div *ngIf="isProviderFound; then providerFound else providerNotFound"></div>

            <ng-template #providerNotFound>
                <div class="row provider-not-found">
                    <div class="col-md-12 mt-2 pt-2">
                        <table>
                            <tr>
                                <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#C44727"
                                        class="bi bi-exclamation-octagon-fill fa-lg right-margin" viewBox="0 0 16 16">
                                        <path
                                            d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                                    </svg></td>
                                <td><b>{{'EV_REQUESTED.DOC_PROVIDER_NOT_FOUND' | translate}}</b></td>
                            </tr>
                            <tr *ngIf="errorMessage === 'undenfined'">
                                <td></td>
                                <td>
                                    <p>{{'EV_REQUESTED.ADDITIONAL_INFO' | translate}}</p>
                                </td>
                            </tr>
                            <tr *ngIf="errorMessage !== 'undenfined'">
                                <td></td>
                                <td>
                                    <p [innerHtml]="errorMessage"></p>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </ng-template>

            <ng-template #providerFound>
                <div class="row provider-found">
                    <div *ngIf="requirement.haveProviders" class="col-md-12 mt-2 pt-2">
                        <table>
                            <tr>
                                <td><i class="fa fa-info-circle icon-background fa-lg" aria-hidden="true"></i></td>
                                <td><b>{{'EV_REQUESTED.DOC_PROVIDER_FOUND' | translate}}</b></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <p>{{'EV_REQUESTED.DOC_PROVIDER_FOUND_DESC' | translate}}</p>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div *ngFor="let evidence of requirement.evidences">
                    <div class="row">
                        <div class="requirement-title-container ml-2 mt-2">
                            <span><b>{{ evidence.evidenceTitle | multiLanguage:lang }}</b></span>
                        </div>

                        <div class="row" *ngIf="evidence.showJurisdictionSelect">
                            <div class="col-md-10" style="margin-bottom: 15px !important;">
                                <div class="ml-3" [formGroup]="jurisdictionForm" (ngSubmit)="onJurisdictionSubmit()">
                                    {{'EV_REQUESTED.ADDITIONAL_INFO' | translate}}
                                    <div *ngFor="let dynamicSelect of dynamicJurisdictionSelects; let i= index"
                                        style="margin-bottom: -12px">

                                        <select2 [data]="jurisdictionsList[i]" class="select2" 
                                        (update)="changeJurisdictionValue($event, i)" listPosition="auto">
                                        </select2>

                                        <!-- <select [formControl]="dynamicSelect" id="dynamicSelect">
                                            <option disabled selected [value]="null"></option>

                                            <option *ngFor="let option of jurisdictionLists[i]" [value]="option.code">
                                                {{option.name}}</option>
                                        </select> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="evidence.providers === null && evidence.jurisdictionLevel === null">
                        <ng-container *ngTemplateOutlet="providerNotFound"></ng-container>
                    </div>

                    <div *ngFor="let provider of evidence.providers; let providerIndex = index;"
                        [ngStyle]="{ 'background-color': providerIndex % 2 === 0 ? '#ECF6FE' : '#FFFFFF' }">

                        <div style="margin-bottom: 5px !important;">
                            <div class="row evidence-discovered ml-1">
                                <span>
                                    <table>
                                        <tr class="spaceUnder ml-2 mt-2">
                                            <td style="white-space:nowrap;">
                                                <input (change)="providerSelected(evidence, providerIndex)"
                                                    type="checkbox" [(ngModel)]="provider.isSelected"
                                                    style="vertical-align: text-bottom;">
                                            </td>
                                            <td>
                                                <u>{{'EV_REQUESTED.PROVIDER' | translate }}</u>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td></td>
                                            <td><span style="font-style: italic;">{{ provider.publisherName }}</span>
                                            </td>
                                        </tr>
                                    </table>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </ng-template>

</div>

<div *ngIf="step === 3" [hidden]="requirement.evidences.length === 0" class="requirement row"
    style="position: relative;">

    <div>
        <p class="no-margin-bottom">
            <label for="requirement-number" class="requirement-number">{{ requirement.number }}</label>
        </p>
    </div>
    <div>
        <p class="no-margin-bottom">
            <label for="requirement-title" class="requirement-title">{{ requirement.title }}</label>
        </p>
    </div>

    <div *ngFor="let evidence of requirement.evidences">
        <div class="row" *ngIf="evidence.selectedProvider !== null">
            <div class="requirement-title-container ml-2 mt-2">
                <div [ngSwitch]="evidence.status">
                    <div *ngSwitchCase="'PENDING'">
                        <ng-container
                            *ngTemplateOutlet="evidencePendingOrError; context: { evidence: evidence }"></ng-container>
                    </div>
                    <div *ngSwitchCase="'ERROR'">
                        <ng-container
                            *ngTemplateOutlet="evidencePendingOrError; context: { evidence: evidence }"></ng-container>
                    </div>
                    <div *ngSwitchCase="'CANCEL'">
                        <ng-container
                            *ngTemplateOutlet="evidenceCanceled; context: { evidence: evidence }"></ng-container>
                    </div>
                    <div *ngSwitchCase="'RECEIVED'">
                        <ng-container
                            *ngTemplateOutlet="evidenceReceived; context: { evidence: evidence }"></ng-container>
                    </div>
                    <div *ngSwitchCase="'PREVIEW_AVAILABLE'">
                        <ng-container
                            *ngTemplateOutlet="evidencePreviewAvailable; context: { evidence: evidence }"></ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="step === 4" [hidden]="requirement.evidences.length === 0" class="requirement row"
    style="position: relative;">

    <div>
        <p class="no-margin-bottom">
            <label for="requirement-number" class="requirement-number">{{ requirement.number }}</label>
        </p>
    </div>
    <div>
        <p class="no-margin-bottom">
            <label for="requirement-title" class="requirement-title">{{ requirement.title }}</label>
        </p>
    </div>

    <div *ngFor="let evidence of requirement.evidences">
        <div class="row" *ngIf="evidence.selectedProvider !== null">
            <div class="requirement-title-container ml-2 mt-2">

                <section>
                    <div class="inline_items">
                        <p><label for="evidence-title" class="evidence-title">{{ evidence.evidenceTitle
                                |multiLanguage:lang }}</label></p>
                        <div class="hr-line">
                            <hr>
                        </div>
                        <div [ngSwitch]="evidence.status">
                            <div *ngSwitchCase="'RECEIVED'">
                                <div class="evidence-status status-received">☑ {{'EV_REQUESTED.STATUS_RECEIVED' |
                                    translate}}</div>
                            </div>
                            <div *ngSwitchCase="'CANCEL'">
                                <div class="evidence-status status-cancel">🛇 {{'EV_REQUESTED.STATUS_CANCEL'
                                    |translate}}</div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>

<ng-template #evidencePreviewAvailable let-evidence="evidence">
    <div class="container-title">
        <div class="evidence-title">{{ evidence.evidenceTitle | multiLanguage:lang }}</div>
        <div class="evidence-status status-pending">⚠ {{'EV_REQUESTED.STATUS_PENDING' | translate }}</div>
    </div>
    <div class="row evidence-waiting">
        <div class="col-md-12 mt-2 pt-2">
            <p><i class="loading"></i>{{'EV_REQUESTED.EVIDENCE_REQUESTED' | translate}}</p>
        </div>
    </div>
</ng-template>

<ng-template #evidenceReceived let-evidence="evidence">
    <div class="container-title">
        <div class="evidence-title">{{ evidence.evidenceTitle | multiLanguage:lang }}</div>
        <div class="evidence-status status-received">☑ {{'EV_REQUESTED.STATUS_RECEIVED' | translate }}</div>
    </div>
    <section>
        <div style="margin-bottom: 5px !important;">
            <div class="row evidence-discovered ml-1">
                <span>
                    <table>
                        <tr class="spaceUnder ml-2 mt-2">
                            <td style="white-space:nowrap;">
                            </td>
                            <td>
                                <u>{{'EV_REQUESTED.PROVIDER' | translate}}</u>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td><span style="font-style: italic;">{{ (evidence !== undefined && evidence.providers !==
                                    undefined) ? evidence.providers[0].publisherName : '' }}</span>
                            </td>
                        </tr>
                    </table>
                </span>
            </div>
        </div>
    </section>
    <section>
        <button *ngIf="!isUserLogged" class="" (click)="viewDocument()">{{'EV_REQUESTED.TRANSLATED_DOCUMENTATION' |
            translate}}</button>
        <button class="view-button" (click)="viewDocument()">{{'EV_REQUESTED.PREVIEW' | translate}}</button>
        <button class="cancel-button" (click)="deleteDocument(evidence)">{{'EV_REQUESTED.DELETE' | translate}}</button>
        <div *ngIf="evidence.base64File && isPdfAvailable" class="w-100">
            <div class="row">
                <ngx-extended-pdf-viewer class="pdf-viewer" [base64Src]="evidence.base64File" [height]="'98vh'">
                </ngx-extended-pdf-viewer>
                <div class="mt-4" style="display: flex; justify-content: flex-end;"><button
                        (click)="viewDocument()">{{'EV_REQUESTED.CLOSE' | translate}}</button></div>
            </div>
        </div>
    </section>
</ng-template>

<ng-template #evidencePendingOrError let-evidence="evidence">
    <div class="container-title">
        <div class="evidence-title">{{ evidence.evidenceTitle | multiLanguage:lang }}</div>
        <div *ngIf="evidence.status === 'PENDING'" class="evidence-status status-pending">⚠
            {{'EV_REQUESTED.STATUS_PENDING' | translate}}</div>
        <div *ngIf="evidence.status === 'ERROR'" class="evidence-status-new status-error">☒
            {{'EV_REQUESTED.STATUS_ERROR' | translate }}</div>
    </div>
    <section>
        <div *ngFor="let provider of evidence.providers; let i = index;">
            <div style="margin-bottom: 5px !important;">
                <div class="row evidence-discovered ml-1" *ngIf="provider.isSelected">
                    <span>
                        <table>
                            <tr class="spaceUnder ml-2 mt-2">
                                <td style="white-space:nowrap;">
                                </td>
                                <td *ngIf="provider.isSelected">
                                    <u>{{'EV_REQUESTED.PROVIDER' | translate}}</u>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td><span style="font-style: italic;">{{ provider.publisherName }}</span>
                                </td>
                            </tr>
                        </table>
                    </span>

                    <div style="display: flex;">
                        <div class="search-button" style="width: 160px;">
                            <span>
                                <table>
                                    <tr>
                                        <td *ngIf="evidence.status === 'PENDING'" style="white-space:nowrap;">
                                            <app-loader-button [disabled]="!provider.isSelected"
                                                (buttonClicked)="searchDocument(evidence)"
                                                [text]="translateText('EV_REQUESTED.SEARCH_DOC')"></app-loader-button>
                                        </td>
                                        <td *ngIf="evidence.status === 'ERROR'" style="white-space:nowrap;">
                                            <app-loader-button [disabled]="!provider.isSelected"
                                                (buttonClicked)="searchDocument(evidence)"
                                                [text]="translateText('EV_REQUESTED.RETRY')"></app-loader-button>
                                        </td>
                                        <td><button class="cancel-button"
                                                (click)="setCancel(evidence)">{{'EV_REQUESTED.CANCEL' |
                                                translate}}</button>
                                        </td>
                                    </tr>
                                </table>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</ng-template>

<ng-template #evidenceCanceled let-evidence="evidence">
    <div class="container-title">
        <div class="evidence-title">{{ evidence.evidenceTitle | multiLanguage:lang }}</div>
        <div class="evidence-status status-cancel">🛇 {{'EV_REQUESTED.STATUS_CANCEL' |
            translate}}</div>
    </div>
    <section>
        <div *ngFor="let provider of evidence.providers; let i = index;">
            <div style="margin-bottom: 5px !important;">
                <div class="row evidence-discovered ml-1" *ngIf="provider.isSelected">
                    <span>
                        <table>
                            <tr class="spaceUnder ml-2 mt-2">
                                <td style="white-space:nowrap;">
                                </td>
                                <td *ngIf="provider.isSelected">
                                    <u>{{'EV_REQUESTED.PROVIDER' | translate}}</u>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td><span style="font-style: italic;">{{ provider.publisherName }}</span>
                                </td>
                            </tr>
                        </table>
                    </span>

                    <div style="display: flex;">
                        <div class="search-button" style="width: 160px;">
                            <span>
                                <table>
                                    <tr>
                                        <td style="white-space:nowrap;">
                                            <button (click)="revertCancel(evidence)">
                                                {{'EV_REQUESTED.REVERT_CANCEL' | translate}}
                                            </button>
                                        </td>
                                    </tr>
                                </table>
                            </span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</ng-template>